* {
  margin: 0;
  padding: 0;
}

/* Import fonts using @font-face */

@font-face {
  font-family: var(--font-sansation);
  src: url("./assets/fonts/Sansation_Regular.ttf") format("truetype");
  /* font-weight: normal;
  font-style: normal; */
}

@font-face {
  font-family: var(--font-sansation);
  src: url("./assets/fonts/Sansation_Light.ttf") format("truetype");
  /* font-weight: normal; */
  /* font-style: normal; */
}

@font-face {
  font-family: var(--font-sansation);
  src: url("./assets/fonts/Sansation_Light_Italic.ttf") format("truetype");
  /* font-weight: normal; */
  /* font-style: italic; */
}

@font-face {
  font-family: var(--font-sansation);
  src: url("./assets/fonts/Sansation_Italic.ttf") format("truetype");
  /* font-weight: normal; */
  /* font-style: italic; */
}

:root {
  /* background-color: #172727; */
  /* custom fonts */
  --font-sansation: "sansation";
  font-family: var(--font-sansation);

  /* google fonts */
  /* font-family: "Syncopate", sans-serif; */

  /* color-scheme: light dark; */
  /* color: rgba(255, 255, 255, 0.87);
  background-color: #1c1c1c; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  /* old theme */
  --light-gray: #f5f5f5;
  --medium-gray: #cccccc;
  --dark-gray: #292929;
  --dark-gray2: #353535;
  --white-tone: #ffffff;
  --light-gont-gray: #afafafd4;
  --semi-mid-gray: #979797;
  --mid-font-color: #756e75;

  /* new theme color */
  --dark-green: #060606;
  --red-tone: #a60841;
  --font-color: #b9b9b9;
  --border-radius: 40px;
  --mid-green-tone: rgba(14, 176, 177, 0.29);
  --dark-green-tone: rgba(11, 75, 76, 0.29);
  --mid-dark-green: #063535;
  --main-padding: 110px;

  /* gradients */
  --button-gradient: linear-gradient(95deg, #bf0eb8 8.76%, #a60841 50.12%);
  --Gradient-Text-Reversed: linear-gradient(
    270deg,
    #ffffff 0%,
    rgba(159, 184, 184, 0) 119.35%
  );
  --Gradient-Text: linear-gradient(
    90deg,
    #ffffff -9.03%,
    rgba(159, 184, 184, 0) 107.1%
  );

  /* new admin dashboard styles */
  --admin-background-green: #182929;
  --ascent-red: #e44444;
  --gray-div-background: #2b2a2a;
  --gray-div-border: #363636;
  --dashboard-black-background: #060606;
  --admin-white-tone: #d9d9d9;
  --chart-green: #7bab7a;
  --check-mark-green: #1fbf1c;
  --icon-font-color: #858892;
  --admin-border-radius: 30px;
  --admin-border-radius2: 20px;
  --admin-border-radius3: 10px;
  --div-padding: 20px;
  --content-gaps: 20px;
  --skeleton-chart-candlecolor: #242424;
}

/* Reset styles for auto-filled inputs */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; /* Change the color to the desired background color */
}

/* Additional styles to reset appearance */
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset; /* Change the color to the desired background color */
}

body {
  background-color: #182929;
  /* font-family: var(--font-sansation), sans-serif; */
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Syncopate", sans-serif;
}
p {
  font-family: var(--font-sansation);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Firefox */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* Hide scrollbar for Internet Explorer and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for modern browsers */
  scrollbar-width: none;
  -webkit-scrollbar {
    display: none;
  }
}
