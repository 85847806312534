.adminhomemain__bodycenter {
  background-color: var(--dashboard-black-background);
  flex: 0.7;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0 0 var(--admin-border-radius2) var(--admin-border-radius2);
  color: var(--admin-white-tone);
  /* padding: var(--div-padding); */
  padding: var(--div-padding);
  padding-bottom: var(--div-padding);
  display: flex;
  flex-direction: column;
  gap: var(--content-gaps);
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.adminhome__defaultfirstdiv {
  /* background-color: red; */
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: var(--div-padding); */
  width: 100%;
  border-radius: var(--admin-border-radius2);
  gap: var(--content-gaps);
  /* box-sizing: content-box; */
}

/* temporal styling */
/* temporal styling */
/* temporal styling */
.adminhome__defaultfirstdiv > img {
  box-sizing: border-box;
  object-fit: contain;
  height: 100%;
}

.summarychart__maindiv {
  height: 100%;
  width: 50%;
  box-sizing: border-box;
  position: relative;
}

.summarychart__maindiv .image__absolutebutton {
  position: absolute;
  object-fit: contain;
  width: 49px;
  bottom: 0;
  right: 3px;
  cursor: pointer;
}

.summarychart__maindivbody {
  width: 100%;
  padding: var(--div-padding);
  background-color: var(--gray-div-background);
  border-radius: var(--admin-border-radius2) var(--admin-border-radius2)
    var(--admin-border-radius2) 0;
  box-sizing: border-box;
  padding-bottom: 0;
  height: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 10px; */
}

.summarychart__maindivbodychart {
  width: 90%;
  height: 100%;
}

.summarychart__maindivbodyicons {
  width: 10%;
  /* background-color: aqua; */
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  border-left: 1px solid var(--gray-div-border);
}

.summarychart__maindivbodyicons img {
  object-fit: contain;
  width: 20px;
  cursor: pointer;
}

.summarychart__maindivfooter {
  width: 90%;
  padding: var(--div-padding);
  background-color: var(--gray-div-background);
  border-radius: 0 0 var(--admin-border-radius2) var(--admin-border-radius2);
  height: 10%;
  box-sizing: border-box;
}

.adminhome__defaultfirstdiv > div:first-child {
  /* background-color: rgb(0, 51, 255); */
  text-align: left;
}

.adminhome__defaultfirstdiv > div:last-child {
  /* background-color: rgb(0, 255, 94); */
  text-align: left;
}

/* LAST DIV */
/* LAST DIV */
/* LAST DIV */
/* LAST DIV */
/* LAST DIV */

.adminhome__defaultlastdiv {
  background-color: var(--gray-div-background);
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: var(--div-padding); */
  /* border-radius: var(--admin-border-radius2); */
  width: 100%;
  /* outline: 1px solid white; */
  /* outline-offset: -40px; */
  border-radius: var(--admin-border-radius2);
}

.adminhome__defaultlastdiv > div:nth-child(2) {
  /* width: 20px;
  height: 30px;
  background-color: brown; */
  border-right: 1px solid var(--gray-div-border);
  height: 85%;
}

.adminhome__defaultlastdiv > div:first-child {
  /* background-color: rgb(0, 142, 14); */
  width: 50%;
  height: 100%;
  text-align: left;
  padding: var(--div-padding);
  box-sizing: border-box;
  /* border-radius: 40% 5% 30% 38%/ 10% 30% 5% 40%; */
  /* outline: 2px solid rgba(87, 87, 87, 0.41); */
  /* outline-offset: -10px; */
}

.adminhome__defaultlastdiv > div:last-child {
  /* background-color: rgb(0, 136, 136); */
  width: 50%;
  height: 100%;
  text-align: left;
  padding: var(--div-padding);
  box-sizing: border-box;
  /* border-left: 1px solid var(--gray-div-border); */
}

.adminhomemain__bodycenter ul {
  list-style-type: none;
  margin-top: var(--div-padding);
}

.adminhomemain__bodycenter ul li {
  padding-bottom: 10px;
  width: 100%;
  padding-top: 10px;
  /* border-bottom: 1px solid var(--gray-div-border); */
  font-size: 14px;
}

.datalistitems__andbullet {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray-div-border);
  cursor: pointer;
}

.datalistitems__andbullet:hover {
  opacity: 0.6;
}

.datalistitems__andbullet > div {
  height: 7px;
  width: 7px;
  background-color: var(--chart-green);
  border-radius: 50%;
  margin-right: 5px;
}

.adminhome__defaultlastdiv > div:first-child p:first-child,
.adminhome__defaultlastdiv > div:last-child p:first-child {
  /* color: red; */
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  /* font-size: 14px; */
  display: flex;
  justify-content: space-between;
}

.adminhomemain__bodycenter
  > div:last-child
  > div:first-child
  p:first-child
  span,
.adminhomemain__bodycenter
  > div:last-child
  > div:last-child
  p:first-child
  span {
  font-size: 14px;
  opacity: 0.3;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .adminhome__defaultfirstdiv {
    flex-direction: column;
    /* height: 100%; */
  }

  .adminhome__defaultlastdiv {
    flex-direction: column;
    /* height: 100%; */
  }

  .adminhomemain__bodycenter {
    overflow-y: none;
  }

  .summarychart__maindiv {
    height: 320px;
    width: 100%;
    /* box-sizing: border-box;
    position: relative; */
  }

  .adminhome__defaultlastdiv > div:first-child,
  .adminhome__defaultlastdiv > div:last-child {
    width: 100%;
  }
}
