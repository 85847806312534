.homepage__main {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: white;
  position: relative;
}

.login__overlayloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgb(250, 250, 250);
  color: var(--dark-gray2);
}

.login__left {
  /* height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #dddddd; */
  /* box-shadow: 1px 1px 5px #ececec; */
  padding: 0 30px 30px 30px;
  /* border-radius: 5px; */
  margin: 30px;
  width: 25%;
  height: 50%;
}

.login__left h3 {
  margin-bottom: 20%;
  /* margin-top: -65px; */
  background-color: rgb(237, 237, 237);
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-left: -220px; */
  color: #969696;

  font-size: small;
}

.login__left > span {
  background: linear-gradient(to right, #09231c 10%, #75e3d2 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: transparent;
}

.signup__forgotpw {
  text-align: center;
  width: 100%;
  font-size: 13px;
  margin-top: 15px;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signup__forgotpw p {
  cursor: pointer;
  /* margin-left: 5px; */
  /* text-align: center; */
}

.login__left form {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-top: 10px;
}

.submit__button {
  margin-top: 10px;
  border-radius: 5px;
  border: none !important;
  cursor: pointer;
  background-color: #252525;
  font-weight: 600;
  color: white;
  height: 45px !important;
  /* font-size: 14px; */
}

.submit__button:hover {
  opacity: 0.95;
}

.login__left form input {
  height: 45px;
  padding-left: 10px;
  border: 2px solid #212121;
  outline: none;
  border-radius: 5px;
  margin-top: 10px;
}

.login__left form input::placeholder {
  color: gray;
}

.login__right {
  flex: 0.75;
  background: linear-gradient(to left, #362938 10%, #2b3642 90%);
  height: 100%;
  display: flex;
}

.login__right img {
  object-fit: contain;
  height: 100%;
}

.login__right::before {
  background: linear-gradient(
    354deg,
    rgba(11, 9, 36, 0.9) 21%,
    rgba(22, 69, 78, 0.2) 77%
  );
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 10;
  width: 75%;
  height: 100%;
}

.login__header {
  display: flex;
  justify-content: flex-start;
  width: 90%;
  margin-top: 20px;
  /* margin-left: 50px; */
}

.login__body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .login__right {
    display: none;
  }

  .login__left {
    flex: 1;
  }
}
