.support___page {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  align-items: center;
  position: relative;
}

.support___page h2 {
  margin: 5px 0;
  text-align: left;
  width: 30rem;
  /* font-weight: 800; */
  font-size: medium;
}

.support___page p {
  font-size: 13px;
  text-align: left;
  width: 30rem;
  line-height: 20px;
  color: var(--icon-font-color);
}

.support___page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 30rem;
}

.support___page form > input,
textarea {
  box-sizing: border-box;
  height: 45px;
  padding: 0 10px;
  border: 1px solid var(--gray-div-border);
  border-radius: 5px;
  outline: none;
  caret-color: var(--chart-green);
  width: 100%;
  font-family: inherit;
  margin-bottom: 10px;
  color: var(--admin-white-tone);
  background-color: transparent;
}

.support___page form > textarea {
  height: 120px;
  padding-top: 10px;
  margin: 5px 0;
  resize: none;
}

.support___page form > input[type="button"] {
  background-color: #182929 !important;
  color: var(--icon-font-color);
  width: 100%;
  cursor: pointer;
  height: 45px;
  border: none;
  /* border-radius: var(--admin-border-radius); */
}

.support___page form > input[type="button"]:hover {
  opacity: 0.9;
  color: #f0f0f0;
}

@media only screen and (max-width: 767px) {
  .support___page form {
    width: 100%;
  }
  .support___page h2 {
    width: 100%;
  }
  .support___page p {
    width: 100%;
  }
}
