.admin__header {
  flex: 0.15;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100px; */
  /* height: 20%; */
  width: 100%;
  /* box-shadow: 0 10px 0 var(--dashboard-black-background); */
}
/* 
.adminheader__left {
  flex: 0.2;
  background-color: var(--dashboard-black-background);
  height: 100%;
  border-top-left-radius: var(--admin-border-radius);
} */

.adminheader__center {
  flex: 0.7;
  background-color: var(--dashboard-black-background);
  height: 100%;
  border-top-right-radius: var(--admin-border-radius);
  color: var(--admin-white-tone);
  display: flex;
  align-items: flex-end;
  padding-left: var(--div-padding);
}

.adminheader__center h3 {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.adminheader__center h3 span {
  font-weight: 300;
  font-size: small;
  font-family: "Courier New", Courier, monospace;
}

.adminheader__right {
  flex: 0.3;
  background-color: var(--dashboard-black-background);
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: top;
}

.adminheader__right::before {
  content: "";
  display: block;
  background-color: var(--admin-background-green);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  border-bottom-left-radius: var(--admin-border-radius);
}

.adminmenu__icons {
  background-color: var(--dashboard-black-background);
  height: 83px;
  width: 92%;
  border-radius: var(--admin-border-radius);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.adminmenu__icons img {
  cursor: pointer;
}

.adminmenu__icons img:hover {
  opacity: 0.8;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .adminheader__right {
    display: none;
  }

  .admin__header {
    display: inline;
  }

  .adminheader__center {
    flex: 1;
    border-top-left-radius: var(--admin-border-radius);
    padding-top: var(--div-padding);
  }

  .adminheader__center h3 {
    margin-top: var(--div-padding);
  }
}

@media only screen and (max-width: 767px) {
  .adminheader__right {
    display: none;
  }

  .admin__header {
    display: inline;
  }

  .adminheader__center {
    flex: 1;
    border-top-left-radius: var(--admin-border-radius);
    padding-top: var(--div-padding);
  }

  .adminheader__center h3 {
    margin-top: var(--div-padding);
  }
}
