.admin__home {
  background-color: var(--admin-background-green);
  height: 95vh;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: top;
  /* width: 100%; */
}

.adminhome__main {
  /* flex: 0.95; */
  width: 93%;
  display: flex;
  flex-direction: column;
}

.adminhomemain__body {
  display: flex;
  height: 85%;
  flex: 0.85;

  /* padding-bottom: 120px; */
}

@media (min-width: 768px) and (max-width: 1024px) {
  .adminhomemain__body {
    flex-direction: column;
  }
  .admin__home {
    height: 100%;
    /* padding: 10px 10px; */
    flex-direction: column;
    /* width: 100%; */
  }
}

@media only screen and (max-width: 767px) {
  .adminhomemain__body {
    flex-direction: column;
  }

  .admin__home {
    height: 100%;
    padding: 10px 10px;
    flex-direction: column;
    width: 100%;
  }
}
