.newadminside__menu {
  /* padding-left: 20px; */
  /* flex: 0.05; */
  width: 7%;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  height: 100%;
  min-width: 90px;
}

.newadminside__menu div:first-child {
  width: 100%;
  background-color: var(--dashboard-black-background);
  /* height: 50px; */
  border-top-left-radius: var(--admin-border-radius);
  border-bottom-left-radius: var(--admin-border-radius);
  display: flex;
  /* align-items: center; */
  /* flex: 0.2; */
  height: 20%;
}

.newadmin__menuarea {
  /* flex: 0.8; */
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: var(--content-gaps);
  justify-content: space-between;
  /* align-items: center; */
  padding: var(--div-padding) var(--div-padding) 0 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: var(--dashboard-black-background);
  z-index: 1;
}

.newadmin__menuarea::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--admin-background-green);
  z-index: -1;
  border-top-right-radius: var(--admin-border-radius);
}

.newadmin_avatar {
  object-fit: contain;
  /* width: 70px; */
  width: 100%;
  flex: 0.1;
  /* justify-self: center; */
}

.newadminsidebar__menuicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  gap: var(--content-gaps);
  background-color: var(--dashboard-black-background);
  border-radius: 20px;
  box-sizing: border-box;
  /* width: 70px !important; */
  width: 100%;
  flex: 0.9;
  /* height: 100%; */
}

.newadminsidebar__menuicon img {
  object-fit: contain;
  width: 18px;
  cursor: pointer;
  padding: 10px 12px;
  border-radius: 10px;
}

.newadminside__menuheader {
  /* height: 20%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.newadminside__menuheader h2 {
  /* margin-top: 50px; */
  /* margin-left: 50px; */
  font-size: medium;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .newadminside__menu {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .newadminside__menu {
    display: none;
  }
}
