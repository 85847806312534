.thirdparty_apis {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.859);
  z-index: 9999;
  /* border-radius: var(--admin-border-radius2); */
  /* border: 1px solid var(--chart-green); */
  backdrop-filter: blur(3px);
  text-align: left;
  padding: var(--content-gaps);
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  /* height: 100vh; */
}

.thirdparty_apis > .thirdparty__apisheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--icon-font-color);
  width: 100%;
  margin-bottom: 50px;
}

.thirdparty_apis > p {
  margin-top: 40px;
  color: var(--icon-font-color);
}

.thirdparty_apis div > h6 {
  font-size: medium;
}

.thirdparty_apis div > p {
  cursor: pointer;
  font-size: medium;
}

.thirdparty_apis form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px; /* Adjust as needed */
  margin-top: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 80vh;
  box-sizing: border-box;
  padding: 25px;
  background-color: rgb(26, 26, 26);
  border-radius: var(--admin-border-radius3);
}

.thirdparty_apis form div {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.thirdparty_apis label {
  color: var(--admin-white-tone);
  margin-bottom: 8px;
  /* width: 90% !important; */
  box-sizing: border-box;
}

.thirdparty_apis input {
  border: 2px solid var(--chart-green);
  border-radius: var(--admin-border-radius3);
  padding: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  color: var(--admin-white-tone);

  box-sizing: border-box;
  width: 100% !important;

  box-sizing: border-box;
}

button {
  background-color: var(--chart-green);
  color: var(--dashboard-black-background);
  border: none;
  border-radius: var(--admin-border-radius3);
  padding: 10px;
  cursor: pointer;
  width: 30%; /* Make the button full-width */
  margin-top: 10px; /* Add margin at the top */
}
