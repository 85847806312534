.chat_space2 {
  /* flex: 0.6; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background-color: white; */
  /* height: 100vh; */
  /* overflow-x: visible; */
  /* position: absolute; */
  /* width: 100%; */
  height: 100% !important;
  /* right: 20px;
  top: 0px;
  bottom: 0px; */
  /* border-radius: 30px; */
  /* padding: 5px; */

  box-sizing: border-box;
  animation: fadeIn 0.5s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.chatspace__footer2 {
  width: 100%;
  /* background-color: #15181c; */
  /* position: fixed; */
  /* bottom: 0; */
  /* z-index: 99; */
}

.footer__info2 {
  height: 30px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__info2 p {
  font-size: 11px;
}

/* .footer__info2 p a {
  color: #7c7c7c;
} */

.footer__info2 h6 {
  font-size: 10px;
  margin-right: 2px;
  margin-bottom: -2px;
  background: linear-gradient(to right, #ff00fe 10%, #03e8ee 90%);
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: transparent;
}

.chatspace__body2 {
  height: 220px;
  padding: 10px 20px;
  /* padding-bottom: 150px; */
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
}

.chatspace__form2 > input {
  height: 45px;
  border: none;
  width: 100%;
  background-color: transparent;
  color: var(--admin-white-tone);
  font-size: 16px;
  margin-left: 40px;
  font-family: var(--font-sansation);
}

.chatspace__form2 > input::placeholder {
  color: #858892;
  /* font-size: 16px; */
}

.chatspace__form2 > input:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.084);
}

.chatspace__form2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-top: 1px solid #3b3b3b; */
}

.chatspace__form2 > input:focus {
  outline: none;
}
.chatspace__form2 > input:active {
  outline: none;
  background-color: transparent;
}

.chat__send2:hover {
  cursor: pointer;
  color: #858892;
}

.chat__send2 {
  font-size: 20px;
  color: #85889247;
  margin-right: 30px;
  transform: rotate(-30deg);
}

.chat__load2 {
  object-fit: contain;
  width: 18px;
  opacity: 0.4;
  /* color: #94bbe9; */
  margin-right: 30px;
}

.header__info2 {
  align-self: center;
  width: 10%;
  border-bottom: 2px solid rgb(255, 255, 255, 0.2);
}

.chatspace__head2 {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #e4e4e4; */
  width: 90%;
  padding: 10px 15px;
  font-size: 14px;
  color: var(--admin-white-tone);
  position: relative;
  /* background: blur(10px); */
}

.chatspace__head2::after {
  content: "";
  left: 10px;
  top: 10px;
  width: 25%;
  height: 60%;
  filter: blur(10px);
  background: #1fbf1c;
  position: absolute;
}

/* .chatspace__head2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #4f4f4f;
  border-right: 10px solid transparent;
  border-top: 10px solid #4f4f4f;
  border-bottom: 10px solid transparent;
  left: 0px;
  top: 35px;
} */

.promptres__div2 {
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
}

.res__div2 {
  grid-column: 1/4;
  grid-row: 1/4;
  display: flex;
  align-items: top;
  margin-top: 5px;
  flex-direction: column;
  width: 100%;
  /* background-color: #72bcbc; */
  background-color: #a4fba3;
  border-radius: 50px;
  border-bottom-left-radius: 0px;
  /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); */
  padding: 15px 20px;
  /* position: relative; */
  color: black !important;
  box-sizing: border-box;
  /* border-start-end-radius: 50px; */
  /* box-shadow: 5px 1px 1px rgb(120, 40, 125); */
}

/* .res__div2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #e1e0f0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -15px;
  top: 0px;
} */

.prompt__div2 {
  grid-column: 1/4;
  grid-row: 1/4;
  display: flex;
  align-items: top;
  margin-top: 5px;
  flex-direction: column;
  width: 100%;
  /* background-color: #e1e0f0; */
  border-radius: 50px;
  border-bottom-right-radius: 0px;
  /* border-bottom-left-radius: 50px; */
  /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); */
  padding: 15px 20px;
  /* position: relative; */
  color: var(--admin-white-tone);
  /* border-start-start-radius: 50px; */
  box-sizing: border-box;
  /* box-shadow: -5px 1px 1px rgb(120, 40, 125); */
}

/* .prompt__div2:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #e1e0f0;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -15px;
  top: 0px;
} */

.res__divwait2 {
  color: black;
}
.response__data2 {
  text-align: left;
  margin-left: 20px;
  white-space: pre-wrap;
}

.prompt__2 {
  text-align: left;
  white-space: pre-wrap;
  margin-top: 5px;
}

.response__2 {
  text-align: left;
  white-space: pre-wrap;
  margin-top: 5px;
}

.user__icon2 {
  margin-top: 10px;
}

.chatspace__istyping22 {
  display: flex;
  align-items: center;
  /* font-size: 14px; */
  /* position: absolute;
  top: 600px; */
  color: #796262;
  /* z-index: 1; */
}

.chatspace__istyping22 img {
  object-fit: contain;
  margin-left: 5px;
  margin-top: 10px;
}

.chatspace__istyping2 {
  margin-top: 50px;
  display: flex;
}

.chatspace__istyping2 img {
  width: 70px;
  object-fit: contain;
  margin-left: 5px;
  margin-top: 5px;
  z-index: 1;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .chat_space2 {
    height: 80vh;
    overflow-x: hidden;
    width: 100%;
    top: 100px;
  }

  .chatspace__footer2 {
    width: 100%;
  }

  .prompt__div2 {
    grid-column: 3/4;
    grid-row: 3/4;
  }

  .res__div2 {
    grid-column: 1/4;
    grid-row: 1/4;
  }
}

@media only screen and (max-width: 767px) {
  .chat_space2 {
    height: 80vh;
    overflow-x: hidden;
    width: 100%;
    top: 100px;
    right: 10px;
    bottom: 40px;
  }

  .prompt__div2 {
    grid-column: 1/4;
    grid-row: 1/4;
  }

  .res__div2 {
    grid-column: 1/4;
    grid-row: 1/4;
  }

  .chatspace__form2 {
    width: 100%;
  }

  .footer__info2 {
    padding-bottom: 25px;
    text-align: left;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }

  .footer__info2 h6 {
    font-size: 8px;
  }

  .footer__info2 p {
    flex: 7;
    font-size: 10px;
    margin-right: 5px;
  }

  .chatspace__body2 {
    padding: 10px 20px 150px 20px;
  }

  .chatspace__istyping2 {
    top: 560px;
  }

  .chatspace__footer2 {
    width: 100%;
  }

  .chatspace__form2 > input:focus {
    border-bottom: none;
  }
}
