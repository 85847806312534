.skeleton__element {
  background: var(--skeleton-chart-candlecolor);
  margin: 10px 0 0 0;
  border-top-right-radius: var(--admin-border-radius);
  border-top-left-radius: var(--admin-border-radius);

  /* height: 100%; */
}

.skeleton__element2 {
  background: var(--skeleton-chart-candlecolor);
  margin: 10px 0 0 0;
  border-radius: var(--admin-border-radius);
  /* height: 100%; */
}

.skeleton__element2.label {
  width: 30%;
  height: 10px;
  transform: rotate(-35deg);
}

.skeleton__element2.label2 {
  width: 10%;
  height: 10px;
  margin-bottom: 10px;
  /* border-start-end-radius: 0; */
  position: relative;
  overflow: hidden;
}

.skeleton__element2.label2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #f0f0f001 25%,
    #e0e0e004 10%,
    #f0f0f003 75%
  ); /* Adjust the gradient colors */
  background-size: 200% 200%;
  animation: moveLight 2s linear infinite;
  /* transform: rotate(90deg); */
}

.skeleton__element.text {
  width: 10px;
  /* height: 40%; */
}

.skeleton__element.title {
  width: 10px;
  /* height: 90%; */
  /* margin-bottom: 15px; */
}

/* .skeleton__element.height {
  height: 50px;
} */

.skeleton__wrapper {
  background: #2b2a2a88;
  border-radius: var(--admin-border-radius2);
  display: flex;
  flex-direction: column;
  /* gap: var(--content-gaps); */
  padding: 30px 50px;
  /* height: 30vh; */
  overflow: hidden;
  position: relative;
}

.skeleton__wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #f0f0f00b 25%,
    #e0e0e018 50%,
    #f0f0f00c 75%
  ); /* Adjust the gradient colors */
  background-size: 200% 100%;
  animation: moveLight 2s linear infinite; /* Adjust the animation duration (2s in this example) */
  transform: rotate(45deg);
}

@keyframes moveLight {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.skeleton__chart {
  /* margin: 10px auto; */
  padding: var(--div-padding);
  padding-bottom: 0;
  /* min-height: 100px; */
  margin-bottom: 10px;
  /* background: var(--gray-div-background); */
  background: url("../../assets/newadmin/misc/grid_background.png");
  background-repeat: initial;
  background-size: contain;
  /* border-radius: var(--admin-border-radius); */
  height: 24vh;
  display: flex;
  gap: calc(var(--content-gaps) * 2);
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #242424;
}

.skeleton__wrapperfooter {
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: calc(var(--content-gaps) / 2);
}
