.apiconnect__component {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

.apiconnect__component > div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  transition: max-height 0.3s ease; /* Add a transition for smooth animation */
}

.apiconnect__component h6 {
  margin: 0;
  font-size: medium;
  color: var(--icon-font-color);
  font-weight: 800;
  cursor: pointer;
}

.apiconnect__component .separator {
  flex: 1;
  margin-left: 16px;
  height: 1px;
  border-top: 1px solid var(--gray-div-border);
  background-color: transparent;
}

.apiconnect__component.collapsed {
  max-height: 40px; /* Set a maximum height for the collapsed state */
}

.apiconnect__component.open {
  max-height: 200px; /* Set a maximum height for the open state */
}

.apiconnect__component .content {
  width: 100%; /* Set the width to 50% when open */
  box-sizing: border-box; /* Include padding and border in the total width */
  padding: 16px; /* Add padding for content */
  /* Additional content styling goes here */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--admin-white-tone);
  justify-content: flex-start;
  text-align: left;
}

.content > div button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--admin-white-tone);
}

.content > div button:hover {
  opacity: 0.9;
}
