.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #141414;
}
::-webkit-scrollbar-thumb {
  background: #435359a2;
  border-radius: 25px;
}
