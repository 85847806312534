.keywords__page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.competitors__mainchart {
  box-sizing: border-box;
  height: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  margin-bottom: calc(var(--content-gaps) * 2);
}

.competitors__mainchart > div {
  box-sizing: border-box;
  background-color: var(--gray-div-background);
  height: 100%;
  /* height: 50%; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  border-radius: var(--admin-border-radius3);
  padding: var(--div-padding);
}

/* .competitors__mainchart > div {
  box-sizing: border-box;
  background-color: var(--gray-div-background);
  height: 100%;
} */

.common__keywordssection {
  display: flex;
  justify-content: space-between;
  height: 40%;
  gap: var(--content-gaps);
  margin-bottom: var(--content-gaps);
}

.common__keywordssectionchild {
  text-align: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* padding: var(--div-padding); */
}

.common__keywordssectionchild div {
  height: 100%;
}

.analyticspage__footer {
  min-height: 20px !important;
  width: 100%;
  background-color: transparent;
}

@media only screen and (max-width: 767px) {
  .common__keywordssection {
    flex-direction: column;
    height: 100%;

    /* margin-bottom: 0; */
  }

  .common__keywordssectionchild {
    height: 250px;
    margin-bottom: 30px;
  }
}
/*
@media (min-width: 768px) and (max-width: 1024px) {
} */
