.settings__main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.authetication__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.authetication__button img {
  width: 30px;
  margin-right: 10px;
}

.authetication__button button {
  border: 1px solid #cecece;
  padding: 5px 10px;
  cursor: pointer;
}

.googleaccount__info {
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 200px;
}

.googleaccount__info p {
  text-align: center;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .googleaccount__info {
    padding: 10px;
  }

  .connect__google {
    margin-top: 50px;
  }
  .connect__google h3 {
    font-size: 14px;
  }
}

/* @media (min-width: 768px) and (max-width: 1024px) {
} */
