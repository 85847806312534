.noapikey__notification {
  position: fixed;
  top: 10px;
  right: 10px;
  /* background-color: #00800086; */
  width: 25% !important;
  color: white;
  height: 70px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);
  /* border-left: 7px solid red; */
  font-size: small;
  z-index: 9999999;
  transform: translateX(100%);
  animation: slideIn 0.5s ease-in-out forwards;
}

.noapikey__notification p:first-child {
  flex: 0.9;
}
@keyframes slideIn {
  to {
    /* Slide the element back to its original position (0%) */
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 767px) {
  .noapikey__notification {
    right: 30px;
    width: 70% !important;
    z-index: 9999999;
    text-align: center;
  }

  .noapikey__notification p:first-child {
    flex: 0.9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .noapikey__notification a {
    margin-left: 5px;
  }
}
