.adminright__sidebar {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  /* box-sizing: border-box; */
}

.adminright__sidebar > div:first-child {
  flex: 0.4;
  background-color: var(--dashboard-black-background);
  border-radius: 0 var(--admin-border-radius) var(--admin-border-radius) 0;
  padding: var(--div-padding);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-left: 0;
}

.acc__hightlights {
  flex: 0.5;
  border-radius: var(--admin-border-radius2);
  background-color: var(--admin-background-green);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--div-padding);
  gap: 10px;
  color: var(--admin-white-tone);
}

.acc__hightlights p {
  cursor: pointer;
  font-size: 14px;
}

.acc__hightlights {
  text-align: left;
}

.acc__hightlights img:last-child {
  position: absolute;
  top: -12px;
  right: 0;
  flex: 0.6;
}

.adminchat__interfacesection {
  flex: 0.6;
  background-color: var(--dashboard-black-background);
  z-index: 1;
  position: relative;
  padding: var(--div-padding);
  padding-bottom: 0;
  padding-right: 0;
}

.adminchat__interfacesection::before {
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: var(--admin-background-green);
  z-index: -1;
  border-top-left-radius: var(--admin-border-radius);
  position: absolute;
}

.adminchat__interfacesection > div {
  border-radius: var(--admin-border-radius2);
  border: 2px solid #0000006b;
  background-color: #243b3baf;
  /* height: 100% !important; */
  /* box-sizing: content-box; */
  margin-top: -2px;
  margin-left: -2px;
}

.chart__interfaceheader {
  height: 7%;
}

.chart__interfacebody {
  height: 75%;
}

.chart__interfacefooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--div-padding);
  height: 12%;
  /* border-top: 1px solid var(--icon-font-color); */
}

.chart__interfacefooter > img {
  object-fit: contain;
  width: 40px;
  border-radius: 50%;
}

.chart__interfacefooter form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: 100%;
}

.chart__interfacefooter form > input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: var(--admin-white-tone);
}

.chart__interfacefooter form > input::placeholder {
  color: var(--icon-font-color);
}

.chart__interfacefooter form > img {
  width: 10%;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .adminright__sidebar > div:first-child {
    display: none;
  }

  .adminchat__interfacesection {
    background-color: transparent;
    padding: 0;
    margin-top: 20px;
    /* width: 100%; */
  }

  .adminchat__interfacesection > div {
    margin: 0;
  }

  .adminchat__interfacesection::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .adminright__sidebar > div:first-child {
    display: none;
  }

  .adminchat__interfacesection {
    background-color: transparent;
    padding: 0;
    margin-top: 20px;
    /* width: 100%; */
  }

  .adminchat__interfacesection > div {
    margin: 0;
  }

  .adminchat__interfacesection::before {
    display: none;
  }

  /* .adminright__sidebar {
    padding: 0;
  } */
}
