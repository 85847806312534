.mobile__menu {
  background-color: var(--dark-green);
  color: var(--icon-font-color);
  height: 30px;
  padding: calc(var(--div-padding) + 10px)
    calc(var(--admin-border-radius3) + 10px);
  width: 100%;
  border: none;
  position: sticky;
  top: 0;
  z-index: 9999;
  box-sizing: border-box;
  /* filter: blur(5px); */
  /* background-color: blur(5px); */
  display: none;
}

.mobile__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.mobile__header > img {
  object-fit: contain;
  width: 22px;
  cursor: pointer;
}

.mobile__header > img:hover {
  transform: rotate(360deg);
}

.mobiledropdown__menu {
  display: none;
}

@media screen and (max-width: 1024px) {
  .mobile__menu {
    display: block;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  .mobiledropdown__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* height: 100%; */
    width: 100%;
    background-color: var(--dark-green);
    padding: calc(var(--div-padding) + 10px)
      calc(var(--admin-border-radius3) + 10px);
    gap: 20px;
    box-sizing: border-box;

    /* overflow: hidden; */
    height: 0;
    opacity: 0;
  }

  /* open menu */

  .mobiledropdown__menu.open {
    height: 100%;
    opacity: 1;
    animation: slideIn 0.3s ease-in;
  }

  @keyframes slideIn {
    from {
      transform: translateY(-100%);
      opacity: 0.1;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* close menu */
  /* .mobiledropdown__menu.close {
    animation: slideOut 0.3s ease-out;
  }

  @keyframes slideOut {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-100%);
      opacity: 0.1;
    }
  } */

  .mobiledropdown__menu > img {
    align-self: self-end;
    cursor: pointer;
  }

  .mobiledropdown__menu ul {
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 90%; */
  }

  .mobiledropdown__menu ul li {
    height: 40px;
    padding-left: 10px;
    border-left: 1px solid var(--icon-font-color);
    width: 100%;
    display: flex;
    align-items: center;
    opacity: 0.8;

    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
      border-left 0.3s ease-in-out;
  }

  .mobiledropdown__menu ul li:hover {
    opacity: 1;
    /* transform: scale(130%); */
    border-left: 2px solid var(--chart-green);

    /* opacity: 1; */
    transform: scale(1.3);
    border-left: 2px solid var(--chart-green);
  }
}
